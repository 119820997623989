import { ErrorContext, ErrorModal } from '@openstax/ui-components';
import React from 'react';

export const ErrorMessageModal = ({children}: {children?: React.ReactNode}) => {
  const { error, setError } = React.useContext(ErrorContext);

  return <ErrorModal heading={error?.error.message} onModalClose={() => setError(null)} show={!!error}>
    {children}
  </ErrorModal>;
};
