import React, {ReactNode} from 'react';
import { makeCreateRoute } from '@openstax/ts-utils/routing';
import { makeComposeMiddleware } from '@openstax/ts-utils/middleware';
import { AppServices } from './types';
import { Location } from "history";

export const composeRequestServiceMiddleware = makeComposeMiddleware<AppServices, {request: Location}>();
export const composeResponseServiceMiddleware = makeComposeMiddleware<AppServices, ReactNode>();

export const createRoute = makeCreateRoute<AppServices, Location>();

export const makeScreen = <P,>(Screen: React.ComponentType<P>, Wrapper?: React.ComponentType) =>
  (params: {} extends P ? undefined : P) =>
    Wrapper ? <Wrapper><Screen {...params as P} /></Wrapper> : <Screen {...params as P} />;
