import React from "react";
import {
  textResizerDefaultValue,
  textResizerMaxValue,
  textResizerMinValue,
  TextResizerValue,
  textResizerValues,
} from "../constants";
import { colors, NavBarPopoverButton } from "@openstax/ui-components";
import styled, { css } from "styled-components";
import textSizeIcon from "../assets/icons/text-size.svg";
import increaseTextSizeIcon from "../assets/icons/text-size-increase.svg";
import decreaseTextSizeIcon from "../assets/icons/text-size-decrease.svg";

const thumbCss = css`
  background: white;
  height: 1.5rem;
  width: 0.7rem;
  border: 1px solid ${colors.palette.neutralDark};
  border-radius: 1px;
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.04),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
`;

const tickMarkCss = css`
  background: repeating-linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0) 19%,
    #fff 19%,
    #fff 20%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0) 20%
  );
`;

interface TextResizerMenuProps {
  gradientColor?: string;
  textSize: number;
}

export const TextResizerMenu = styled.div`
  color: ${colors.palette.neutralDark};

  text-align: left;
  font-weight: bold;

  label {
    display: block;
    padding: 1.5rem 1.6rem 0;
  }

  .controls {
    padding: 0.2rem 0.6rem 0.2rem;
    display: flex;
    align-items: center;

    > button {
      height: 4rem;
      width: 4.8rem;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: #f1f1f1;
      ${({ gradientColor }: TextResizerMenuProps) =>
        gradientColor &&
        css`
          background-image: linear-gradient(${gradientColor}, ${gradientColor});
        `}
      background-size:
        ${({ textSize }: TextResizerMenuProps) => `
          calc(
            (${textSize} - ${textResizerMinValue}) * 100 / (${textResizerMaxValue} - ${textResizerMinValue}) * 1%
          ) 100%
        `};
      background-repeat: no-repeat;
      overflow: visible;
      height: 0.4rem;
      width: 12rem;
      margin: 0.8rem 1.4rem;
    }

    input[type="range"]::-webkit-slider-runnable-track,
    input[type="range"]::-moz-range-track {
      -webkit-appearance: none;
      -moz-appearance: none;
      box-shadow: none;
      border: none;
      height: 0.2rem;
      ${tickMarkCss}
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 0.2rem;
      ${tickMarkCss}
    }

    input[type="range"]::-moz-range-thumb {
      ${thumbCss}
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      ${thumbCss}
      width: 0.8rem;
      margin-top: -6px;
    }
  }
`;

const StyledButton = styled.button`
  background: 0;
  border-color: transparent;
  cursor: pointer;
`;

export interface TextResizerProps {
  bookTheme?: string;
  textSize: TextResizerValue | null;
  setTextSize: (value: TextResizerValue) => void;
  mobileToolbarOpen?: boolean;
  mobileVariant?: boolean;
}

export const TextResizer = (props: TextResizerProps) => {
  const onChangeTextSize = (e: React.FormEvent<HTMLInputElement>) => {
    const target = (e as any).currentTarget;
    const value = parseInt(target.value, 10) as TextResizerValue;
    if (!textResizerValues.includes(value)) {
      return;
    }
    props.setTextSize(value);
  };

  const onDecreaseTextSize = (
    e:
      | React.FormEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    const newValue = (props.textSize || textResizerDefaultValue) - 1;
    if (newValue < textResizerMinValue) {
      return;
    }
    props.setTextSize(newValue as TextResizerValue);
  };

  const onIncreaseTextSize = (
    e:
      | React.FormEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    const newValue = (props.textSize || textResizerDefaultValue) + 1;
    if (newValue > textResizerMaxValue) {
      return;
    }
    props.setTextSize(newValue as TextResizerValue);
  };

  if (props.textSize === null) {
    return null;
  }

  return (
    <NavBarPopoverButton icon={textSizeIcon} aria-label="Change text size">
      <TextResizerMenu
        textSize={props.textSize}
        gradientColor={colors.palette.neutralDark}
      >
        <label id="text-resizer-label">Text size</label>
        <div className="controls">
          <StyledButton
            aria-label="Decrease text size"
            onClick={onDecreaseTextSize}
          >
            <img src={decreaseTextSizeIcon} alt="" />
          </StyledButton>
          <input
            type="range"
            step="1"
            min={textResizerMinValue}
            max={textResizerMaxValue}
            onChange={onChangeTextSize}
            value={props.textSize}
            data-testid="change-text-size"
            aria-labelledby="text-resizer-label"
          />
          <StyledButton
            aria-label="Increase text size"
            onClick={onIncreaseTextSize}
          >
            <img src={increaseTextSizeIcon} alt="" />
          </StyledButton>
        </div>
      </TextResizerMenu>
    </NavBarPopoverButton>
  );
};
