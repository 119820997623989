import React from 'react';
import styled from "styled-components";
import { Error, Loader } from '@openstax/ui-components';

const StyledLoader = styled(Loader)`
  height: 10rem;
`;

type WrapperProps = {
  error: string;
  loading: boolean;
};

const ActivityWrapper = (props: React.PropsWithChildren<WrapperProps>) =>
  <>
    {props.loading && !props.error
      ? <StyledLoader />
      : props.error
        ? <Error>{props.error}</Error>
        : <>{props.children}</>}
  </>;

export default ActivityWrapper;
