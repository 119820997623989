import React from 'react';
import { ToastProvider } from "../../toasts/ToastContext";
import type {ActivityState} from '@openstax/ts-utils/services/lrsGateway/attempt-utils';
import * as UI from '@openstax/ui-components';
import type {MappedUserInfo} from '@openstax/ts-utils/services/accountsGateway';
import { createRoute, makeScreen } from "../../core/services";
import { stateHasData } from "@openstax/ts-utils/fetch";
import { useGetAssignment } from "../hooks/assignments";
import { Auth } from "../components/Auth";
import { AssignmentSubmissionList } from "../components/AssignmentSubmissionList";
import { AssignmentSubmissionDetails } from "../components/AssignmentSubmissionDetails";
import { useLaunchTokenData } from "../../auth/useTokenData";
import { useAssignmentManagementStates, AssignmentManagementStates } from "../hooks/grades";
import type {
  AssignmentReadResponse
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";

export const ReviewSubmissionInterface = ({assignment, assignmentManagementStates, onEditAssignment}: {
  assignment: AssignmentReadResponse;
  assignmentManagementStates: AssignmentManagementStates;
  onEditAssignment?: () => void;
}) => {
  const launchTokenData = useLaunchTokenData();
  const [selectedAttempt, selectAttempt] = React.useState<MappedUserInfo<ActivityState> | undefined>();

  return <>
    {launchTokenData.branding !== false ? <UI.NavBar logo={true} /> : null}
    {selectedAttempt
      ? <AssignmentSubmissionDetails
        onBack={() => selectAttempt(undefined)}
        assignment={assignment}
        attempt={selectedAttempt}
      />
      : <AssignmentSubmissionList
        onEditAssignment={onEditAssignment}
        onSelectAttempt={selectAttempt}
        assignment={assignment}
        assignmentManagementStates={assignmentManagementStates}
      />
    }
  </>;
};
export const ReviewSubmissionScreenComponent = ({assignmentId}: {assignmentId: string}) => {
  const assignment = useGetAssignment(assignmentId);
  const assignmentManagementStates = useAssignmentManagementStates(assignmentId);

  return <ToastProvider>
    <Auth>
      {stateHasData(assignment)
        ? <ReviewSubmissionInterface
            assignment={assignment.data}
            assignmentManagementStates={assignmentManagementStates}
        />
        : null
      }
    </Auth>
  </ToastProvider>;
};

export const reviewSubmissionScreen = createRoute({name: 'reviewSubmissionScreen',
  path: '/instructor/review/:assignmentId',
  handler: makeScreen(ReviewSubmissionScreenComponent)
});
