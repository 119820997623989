import React from 'react';
import { User } from "@openstax/ts-utils/services/authProvider";
import { useAuth } from "../../auth/useAuth";
import {
  stateHasData, stateHasError, FetchState
} from "@openstax/ts-utils/fetch";
import { SessionExpiredError } from "@openstax/ts-utils/errors";
import * as UI from '@openstax/ui-components';
import { useLaunchTokenFields } from "../../auth/useTokenData";

export const Auth = (
  props: React.PropsWithChildren<{}> | {children: (state: FetchState<User, string>) => React.ReactNode}
) => {
  const authState = useAuth();
  const token = useLaunchTokenFields();
  const [showSessionWarning, setSessionWarning] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!('exp' in token)) return;

    const minTimeout = 1;
    const maxTimeout = 36000000; // 10 hours
    const warningTime = 300000; // 5 minutes
    const timeToWarning = Math.min(maxTimeout,
      Math.max(minTimeout, token.exp * 1000 - Date.now() - warningTime)
    );

    const timeout = setTimeout(() => {
      setSessionWarning(true);
    }, timeToWarning);

    return () => clearTimeout(timeout);
  }, [setSessionWarning, token]);

  /* error establishing auth state */
  if (stateHasError(authState)) {
    return <UI.Error />;
  }

  if (stateHasData(authState) && !authState.data) {
    throw new SessionExpiredError();
  }

  const children = typeof props.children === 'function'
    ? props.children(authState as FetchState<User, string>)
    : props.children;

  return <>
    <UI.Modal heading='Heads up!' onModalClose={() => setSessionWarning(false)} show={showSessionWarning}>
      <UI.ModalBody>Your session will expire soon. Please reload the page to avoid losing your progress.</UI.ModalBody>
    </UI.Modal>
    {children}
  </>;
};
