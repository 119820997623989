import React from 'react';
import * as UI from '@openstax/ui-components';

const ToastContext = React.createContext<{toasts: UI.ToastData[]; pushToast: (toast: UI.ToastData) => void}>({
  toasts: [],
  pushToast: () => { throw new Error('tried to use pushToast without a ToastProvider');}
});

export const ToastProvider = ({children}: React.PropsWithChildren<{}>) => {
  const [toasts, setToasts] = React.useState<UI.ToastData[]>([]);

  const pushToast = React.useCallback((toast: UI.ToastData) => {
    setToasts(previous => [...previous, toast]);
  }, [setToasts]);

  return <ToastContext.Provider value={{toasts, pushToast}}>
    {children}
  </ToastContext.Provider>;
};

export const Toasts = () => {
  const {toasts} = React.useContext(ToastContext);
  return toasts.length > 0 ? <UI.ToastContainer toasts={toasts} /> : null;
};

export const usePushToast = () => {
  const {pushToast} = React.useContext(ToastContext);
  return pushToast;
};
