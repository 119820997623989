import { AnyOrnResource } from "@openstax/orn-locator";

declare const window: Window & {dataLayer: any[]};

window.dataLayer = window.dataLayer || [];

export const setContentMetadata = (content: Partial<AnyOrnResource>, scope?: Partial<AnyOrnResource>) => {
  if (!('id' in content) || (scope && !('id' in scope))) return;

  window.dataLayer.push({'os.content_metadata': {
    contentVersion: '',
    contentId: content.id,
    scopeId: scope?.id 
  }});
};
