import { css, FlattenSimpleInterpolation } from "styled-components";

export const visuallyHidden = `
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0,0,0,0) !important;
  white-space: nowrap !important;
  border: 0 !important;
`;


export const narrowMediaQuery = (style: FlattenSimpleInterpolation) => css`
   @media (max-width: 30em) {
     ${style}
   }
`;
