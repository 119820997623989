import React from 'react';
import classNames from 'classnames';
import { Button, colors } from '@openstax/ui-components';
import styled from "styled-components";

const StyledActivityFooter = styled.div`
  transition: height 300ms ease-in-out;
  background: ${colors.palette.neutralBright};
  border-top: 1px solid ${colors.palette.pale};
  height: 0;
  overflow: hidden;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  &.active {
    height: 6rem;
  }

  button {
    margin-right: 3.2rem;
  }
`;

type FooterProps = {
  loading: boolean;
  isCompleted: boolean;
  canShow: boolean;
  onNext: () => void;
  onComplete: () => void;
};

const ActivityFooter = (props: FooterProps) => {
  const [completeButtonEnabled, setCompleteButtonEnabled] = React.useState(false);

  React.useEffect(() => {
    if (!props.canShow || props.isCompleted) return;
  
    const timer = setTimeout(() => {
      setCompleteButtonEnabled(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, [props.canShow, props.isCompleted]);

  return <StyledActivityFooter className={classNames({active: props.canShow})}>
    {props.isCompleted
      ? <Button onClick={props.onNext}>Next Step</Button>
      : <Button
        onClick={props.onComplete}
        isWaiting={props.loading}
        waitingText="Completing..."
        disabled={!completeButtonEnabled}
      >Mark as complete and continue
      </Button>}
    </StyledActivityFooter>;
};

export default ActivityFooter;
