import React from 'react';
import checkmark from '../assets/icons/checkmark.svg';
import pencil from '../assets/icons/pencil.svg';
import { OldToolTip } from './ToolTip';
import { colors } from '@openstax/ui-components';
import styled, {css} from "styled-components";
import classNames from 'classnames';
import openstaxLogoMobile from '../assets/logo-mobile.svg';
import openstaxLogo from '../assets/logo.svg';
import rightArrow from '../assets/icons/right.svg';
import leftArrow from '../assets/icons/left.svg';
import useMatchMobileQuery from '../utils/reactUtils';

const collapsedStyles = css`
  width: 5.6rem;

  .logos {
    margin: 0;
  }

  .logo {
    margin-left: 0;
    max-width: 3.8rem;
    width: 100%;

    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }

  .activities {
    align-items: center;
  }

  .activities button .title, .activities button .status, .activities button .status.complete, .scope span {
    display: none;
  }
  .activities button .icon {
    margin-right: 0;
  }`;

const AssignmentWrapper = styled.div`
  font-family: "Neue Helvetica W01", Helvetica, Arial, sans-serif;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  nav {
    position: relative;
    transition: width 300ms ease-in-out;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 29.6rem;
    background: ${colors.palette.neutralBright};
    color: ${colors.palette.neutralThin};
    font-size: 1.4rem;
    font-family: "Neue Helvetica W01", Helvetica, Arial, sans-serif;

    line-height: 2rem;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1) inset;

    &.collapsed {
      ${collapsedStyles}
    }

    .logos {
      height: 8.8rem;
      margin: 0 -0.8rem;
      border-bottom: 1px solid ${colors.palette.pale};
    }

    .logo {
      margin-left: 0.8rem;
      width: 10.5rem;
      &.mobile {
        display: none;
      }
    }

    .scope {
      display: flex;
      align-items: center;
      color: ${colors.palette.neutralDarker};
      text-decoration: none;
      font-size: 1.6rem;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
      img {
        width: 7.2rem;
        margin-right: 0.8rem;
      }
    }
    button {
      margin-bottom: 1.6rem;
    }

    .activities {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding-top: 2.4rem;

      button {
        position: relative;
        color: ${colors.palette.neutralThin};
        display: flex;
        align-items: center;
        margin: 0 0 1.6rem 0;
        padding: 0;
        cursor: pointer;
        background: none;
        border: 0;
        text-align: left;
        line-height: 2rem;

        &.active {
          color: ${colors.palette.mediumBlue};
          .icon {
            background: ${colors.palette.mediumBlue};
          }

          .status {
            border-color: ${colors.palette.mediumBlue};
          }
        }

        &:hover {
          text-decoration: underline;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2rem;
          width: 2rem;
          margin-right: 0.8rem;
          background: ${colors.palette.neutralThin};
          color: ${colors.palette.white};
          flex-shrink: 0;

          img {
            height: 1.1rem;
          }
        }

        .title {
          flex-grow: 1;
          min-width: 0;
          margin-right: 1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .status {
          width: 1.6rem;
          height: 1.6rem;
          border: 0.2rem solid ${colors.palette.pale};
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;

          &.complete {
            background: ${colors.palette.mediumBlue};
            border-color: ${colors.palette.mediumBlue};

            img {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 1rem;
            }
          }
          }
        }
      }
    }
  }

  iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }
`;

const NavTop = styled.div`
  height: 100%;
  padding: 1.6rem;
  overflow-y: auto;

  button.toggleNav {
    position: absolute;
    right: 0;
    top: 5rem;
    width: 1.6rem;
    height: 4rem;
    background: #979797;
    padding: 0;
    border: 0;
    cursor: pointer;
  }

  &.collapsed {
    padding: 1.6rem 0.8rem;

    button.toggleNav {
      left: 0;
    }
  }
`;

const NavBottom = styled.div`
  transition: padding 200ms ease-in-out;
  &:not(:empty) {
    padding: 1.6rem;
  }
  button {
    width: 100%;
  }

  &.collapsed {
    padding: 0.8rem;

    .scope img {
      margin-right: 0;
      width: 4rem;
    }
  }
`;

const ActivityWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 0;
`;

const TitleToolTip = styled(OldToolTip)`
  max-width: 20rem;
  white-space: normal;
`;

export const ActivitiesDisplay = ({sidebar, navBottom, children}: React.PropsWithChildren<{
  navBottom?: React.ReactNode;
  sidebar: {
    title: string;
    id: string;
    icon?: string;
    active: boolean;
    onClick: () => void;
    isComplete?: boolean;
  }[];
}>) => {
  const isMobile = useMatchMobileQuery();
  const [navIsCollapsed, setNavIsCollapsed] = React.useState(isMobile);

  return <AssignmentWrapper>
    <nav className={classNames({collapsed: navIsCollapsed})}>
      <NavTop className={classNames({collapsed: navIsCollapsed})}>
        <button className="toggleNav" onClick={() => setNavIsCollapsed(!navIsCollapsed)}>
          {navIsCollapsed
            ? <img src={rightArrow} alt='right arrow'/>
            : <img src={leftArrow} alt='left arrow'/>
          }
        </button>
        <div className="logos">
          <img src={openstaxLogo} alt='OpenStax' className='logo desktop' />
          <img src={openstaxLogoMobile} alt='OpenStax' className='logo mobile' />
        </div>
        <div className="activities">
          {sidebar.map((activity, index) => <button
            onClick={activity.onClick}
            key={activity.id}
            className={classNames({active: activity.active})}>
            <span className="icon">
              {(<img src={activity.icon || pencil} aria-hidden={true} alt='' />)}
            </span>
            <div className='title'>
              <span>{activity.title}</span>
              {/* TO-DO: add js to detect which titles are truncated and only show a tooltip in that case */}
              <TitleToolTip>{activity.title}</TitleToolTip>
            </div>
            {'isComplete' in activity ? <span className={classNames('status', {complete: activity.isComplete})}>
              {activity.isComplete ? (<img src={checkmark} alt='complete' />) : null}
            </span> : null}
          </button>)}
        </div>
      </NavTop>

      {navBottom ? <NavBottom className={classNames({collapsed: navIsCollapsed})}>
        {navBottom}
      </NavBottom> : null}
    </nav>
    <ActivityWrapper>
      {children}
    </ActivityWrapper>
  </AssignmentWrapper>;
};
