import styled, {createGlobalStyle, css} from 'styled-components';
import * as UI from '@openstax/ui-components';
import { visuallyHidden } from '../../../utils/styleUtils';

export const GlobalStyle = createGlobalStyle`
  #root {
    display: flex;
    flex-direction: column;
  }

  a {
    text-decoration: none;
    color: ${UI.colors.palette.mediumBlue};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Main = styled.div<{navVisible?: boolean}>`
  flex: 1;
  display: ${props => props.navVisible ? 'grid' : 'block'};
  grid-template: 100% / 24rem auto;
  overflow: ${props => props.navVisible ? 'hidden' : 'auto'};
`;

export const ActionBar = styled.div<{hide: boolean; flexEnd: boolean}>`
  background: #f4f4f4;
  transition-property: height padding;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  max-height: 5.6rem;
  padding: 1.3rem 3rem;
  border-top: 1px solid #d5d5d5;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.flexEnd ? 'flex-end' : 'space-between'};
  align-items: center;

  button {
    font-size: 1.4rem;
    max-height: 3rem;
    margin-left: 1.6rem;

    &:first-child {
      margin-left: none;
    }
  }

  ${(props) => props.hide ? css`
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    * {
      display: none;
    }
  ` : ''}
`;

export const SubContentSidebar = styled.nav`
  background-color: ${UI.colors.palette.neutralBright};
  display: grid;
  align-content: start;

  header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid ${UI.colors.palette.pale};

    h1 {
      font-size: 1.4rem;
      margin-right: 1.4rem;
      overflow-wrap: break-word;
      overflow: hidden;
    }

    button.transparent {
      background: none;
      border-radius: 0.4rem;
      line-height: 0;
      padding: 0;
      margin: 0.7rem 0.4rem 0.5rem 0.7rem;
      height: 2.5rem;
      width: 2.5rem;

      &:not(:hover) {
        box-shadow: none;
      }

      svg {
        width: 2.5rem;
      }
    }
  }

  ol {
    list-style: none;
    margin: 1.4rem 0 0;
    padding: 0;
    overflow: hidden auto;

    button {
      padding: 0.5rem 1.4rem;
      text-align: left;
      width: 100%;
      background: none;
      border: none;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 2.0rem;
      color: ${UI.colors.palette.neutralThin};
      cursor: pointer;
      display: flex;

      &:hover {
        background-color: #ddd;
      }
      &.selected {
        background-color: ${UI.colors.palette.mediumBlue};
        color: ${UI.colors.palette.white};
      }

      > span {
        display: flex;
        flex-grow: 1;
      }
      small {
        background: ${UI.colors.palette.neutralThin};
        color: ${UI.colors.palette.white};
        border-radius: 1rem;
        min-width: 2.5rem;
        height: 1.5rem;
        margin: 0.3rem 0 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.selected small {
        background: ${UI.colors.palette.white};
        color: ${UI.colors.palette.mediumBlue};
      }
    }

    // visually hide "Chapter" from titles
    .os-part-text {
      ${visuallyHidden}
    }
    .os-number {
      min-width: 2.2rem;
    }

  }
`;

export const MainContent = styled.main`
  overflow: hidden auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
`;

export const ModalBody = styled.div`
  padding: 3.2rem;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6rem;
`;
