import {
  RegistrationMetadataPayload,
} from '@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/registrationMetadata';
import {
  FetchState,
  fetchIdle,
  fetchLoading,
  fetchSuccess,
} from '@openstax/ts-utils/fetch';
import React from 'react';
import { useApiClient } from '../../api';
import { useSetAppError } from '@openstax/ui-components';

export const useRegistrationMetadata = (registration?: string) => {
  const apiClient = useApiClient();

  type ResponsePromise = ReturnType<
    typeof apiClient.apiV0GetRegistrationMetadata | typeof apiClient.apiV0WriteRegistrationMetadata
  >;

  const [state, setState] = React.useState<FetchState<RegistrationMetadataPayload, string>>(
    registration ? fetchLoading() : fetchIdle()
  );

  const setAppError = useSetAppError();

  const setStateFromResponse = React.useCallback((responsePromise: ResponsePromise) => {
    return responsePromise.then((response) => response.acceptStatus(200).load())
                          .then((response) => setState(fetchSuccess(response)))
                          .catch(setAppError);
  }, [setAppError]);

  React.useEffect(() => {
    if (registration) {
      setStateFromResponse(apiClient.apiV0GetRegistrationMetadata({params: { registration }}));
    }
  }, [apiClient, registration, setStateFromResponse]);

  const save = React.useCallback(async(payload: RegistrationMetadataPayload) => {
    if (registration) {
      setState((previous) => fetchLoading(previous));

      return setStateFromResponse(apiClient.apiV0WriteRegistrationMetadata({ params: { registration }, payload }));
    }
  }, [apiClient, registration, setStateFromResponse]);

  return [state, save] as const;
};

export type SetRegistrationMetadata = ReturnType<typeof useRegistrationMetadata>[1]; 
