import { Button, H2, Paragraph } from '@openstax/ui-components';
import styled from "styled-components";

const StyledProgress = styled.div`
  padding: 8.8rem 4rem;

  h2 {
    margin: 0;
  }

  p {
    margin: 1.6rem 0 2rem 0;
  }
`;

const Progress = (props: {numIncomplete: number; numActivities: number; buttonHandler: () => void}) =>
  <StyledProgress>
    <H2>{props.numIncomplete ? 'Assignment is partially complete.' : 'Great job finishing the assignment.'}</H2>
    <Paragraph>{props.numIncomplete
      ? `You've completed ${props.numActivities - props.numIncomplete} of ${props.numActivities} tasks.`
      : 'You are done.'}
    </Paragraph>
    {props.numIncomplete ? <Button onClick={props.buttonHandler}>Next</Button> : null}
  </StyledProgress>;

export default Progress;
