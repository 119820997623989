import {
  FetchState,
  fetchLoading,
  fetchSuccess,
  fetchIdle
} from "@openstax/ts-utils/fetch";
import React from 'react';
import { locate, locateAll, AnyOrnLocateResponse } from "@openstax/orn-locator";
import { useSetAppError } from "@openstax/ui-components";

export const useOrn = (orn: string | undefined) => {
  const setAppError = useSetAppError();
  const [state, setState] = React.useState<
    FetchState<AnyOrnLocateResponse, string>
  >(orn ? fetchLoading() : fetchIdle());

  React.useEffect(() => {
    if (!orn) {
      return;
    }
    locate(orn)
      .then(response => {
        setState(fetchSuccess(response));
      })
      .catch(setAppError);
  }, [orn, setAppError]);

  return state;
};

export const useOrns = (orns: string[] | undefined) => {
  const setAppError = useSetAppError();
  const [state, setState] = React.useState<
    FetchState<AnyOrnLocateResponse[], string>
  >(orns !== undefined ? fetchLoading() : fetchIdle());

  React.useEffect(() => {
    if (orns === undefined) {
      return;
    }
    if (orns.length < 1) {
      setState(fetchSuccess([]));
      return;
    }

    locateAll(orns)
      .then(responses => {
        setState(fetchSuccess(responses));
      })
      .catch(setAppError)
    ;
  }, [orns, setAppError]);

  return state;
};
