import { merge } from "@openstax/ts-utils/misc/merge";
import {v4 as uuid} from 'uuid';
import {
  IntegrationSearchResultActivity,
  IntegrationSearchResultItem,
  IntegrationSearchResult,
  Topics
} from '@project/lambdas/build/src/services/activityConfigResolver';
import {
  Activity,
  IntegrationActivity
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/types";
import {
  AssignmentReadResponse
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";

export type FlattenedSelectedActivity = IntegrationSearchResultActivity<Activity> & {removed?: boolean};
export type FlattenedPersistedActivity = AssignmentReadResponse['activities'][number] & {removed?: boolean};
export type FlattenedActivityStep = FlattenedSelectedActivity | FlattenedPersistedActivity;

export const stepIsRemoved = (activity: FlattenedActivityStep | undefined) =>
  activity === undefined || ('removed' in activity && activity.removed);

export const flattenSearchResult = (
  result: IntegrationSearchResultItem<IntegrationActivity>
): IntegrationSearchResultActivity<IntegrationActivity>[] => {
  if ('items' in result) {
    return result.items.flatMap(flattenSearchResult);
  } else {
    return [result];
  }
};

export const addPersistableFields = (
  result: (IntegrationSearchResult<IntegrationActivity> & IntegrationSearchResultActivity<IntegrationActivity>) |
    IntegrationSearchResultActivity<IntegrationActivity>
  ,
  attachedTo?: FlattenedActivityStep,
): FlattenedSelectedActivity => {
  return {
    ...result,
    activity: {
      ...result.activity,
      stepId: uuid(),
      attachedTo: attachedTo && attachedTo.activity.stepId
    }
  };
};

export const matchTopicsFor = (selected: {topics: Topics; attachedTo?: string}[]) => {
  const coveredTopics = merge(...selected.map(({attachedTo, topics}) => attachedTo === undefined ? topics : {}));
  const topicCovered = (check: IntegrationSearchResultItem<IntegrationActivity>): boolean =>
    'items' in check
      ? check.items.every(topicCovered)
      : Object.keys(check.topics).every(topic => !!coveredTopics[topic]);

  return topicCovered;
};

type CheckGuard<R extends IntegrationSearchResultItem<IntegrationActivity>> =
  (check: IntegrationSearchResultItem<IntegrationActivity>) => check is R;

type Check = (check: IntegrationSearchResultItem<IntegrationActivity>) => boolean;

export const filterAddon = <
  R extends IntegrationSearchResultItem<IntegrationActivity> = IntegrationSearchResultItem<IntegrationActivity>
>(check: CheckGuard<R> | Check) => {

  const filterAddonForCheck = (
    addon: IntegrationSearchResultItem<IntegrationActivity>
  ): R[] => {

    return check(addon)
      ? [addon]
      : 'items' in addon
        ? addon.items.flatMap(filterAddonForCheck)
        : [];
  };

  return filterAddonForCheck;
};

export const matchStep = (one: FlattenedActivityStep) => (two: FlattenedActivityStep) =>
  one.activity.stepId === two.activity.stepId;

export const hasStep = (items: FlattenedActivityStep[] | undefined, step: FlattenedActivityStep) =>
  items === undefined
    ? false
    : items.find(matchStep(step));
