import { isSyncScoresCompleteMessage, makeSyncScoresMessage, SyncScoresCompleteMessage } from '@openstax/lti';
import {
  FetchStateType, FetchState,
  fetchIdle, fetchLoading,
  fetchSuccess, fetchError
} from '@openstax/ts-utils/fetch';
import {
  AssignmentAttemptDetail
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";
import type {MappedUserInfo} from '@openstax/ts-utils/services/accountsGateway';
import type {ActivityState} from '@openstax/ts-utils/services/lrsGateway/attempt-utils';
import { useSetAppError } from '@openstax/ui-components';
import { saveAs } from 'file-saver';
import React from 'react';
import { useApiClient } from '../../api';
import {
  CsvDownloadError,
  NoScoresError,
  ScoresSyncError,
  StudentsNotInCourse,
  UpdateLimitReached,
} from '../../errors';
import { useServices } from "../../core/context/services";
import { usePushToast } from "../../toasts/ToastContext";
import { SetRegistrationMetadata, useRegistrationMetadata } from "../utils/registration-metadata";
import { useLaunchTokenData } from "../../auth/useTokenData";

export const useSyncScores = (assignmentId: string | undefined, setRegistrationMetadata: SetRegistrationMetadata) => {
  const setAppError = useSetAppError();
  const pushToast = usePushToast();
  const [state, setState] = React.useState<FetchState<SyncScoresCompleteMessage, Error>>(
    fetchIdle()
  );

  const handleScoreCompleteMessage = React.useCallback((data: SyncScoresCompleteMessage) => {
    const numFailures = data.failures.length;
    const numSuccesses = data.successIds.length;

    const error = numFailures > 0
      ? data.failures.every((failure) => failure.message === 'Student not in course')
        ? new StudentsNotInCourse(data.failures)
        : data.failures.every((failure) => failure.message === 'Update limit reached')
          ? new UpdateLimitReached(data.failures)
          : new ScoresSyncError(data.failures)
      : numSuccesses === 0
        ? new NoScoresError()
        : undefined;

    if (error) {
      setState(previous => fetchError(error, previous));
    } else {
      setRegistrationMetadata({ lastScoresSync: Date.now() }).then(() => {
        pushToast({
          title: 'Success',
          message: `${data.successIds.length} scores sent successfully`,
          variant: 'success',
          dismissAfterMs: 5000
        });
        setState(fetchSuccess(data));
      });
    }
  }, [pushToast, setRegistrationMetadata]);

  React.useEffect(() => {
    if (state.type === FetchStateType.ERROR) {
      setAppError(state.error);
    }
  }, [state, setAppError]);

  const postSyncScores = React.useCallback((syncIncompleteScores: boolean) => {
    if (!assignmentId) return;

    setState(previous => fetchLoading(previous));

    const handleCallback = (e: MessageEvent<any>) => {
      const { data } = e;

      if (isSyncScoresCompleteMessage(data)) {
        window.removeEventListener('message', handleCallback);
        handleScoreCompleteMessage(data);
      }
    };

    window.addEventListener('message', handleCallback, false);
    window.parent.postMessage(makeSyncScoresMessage(assignmentId, syncIncompleteScores), '*');

  }, [handleScoreCompleteMessage, assignmentId]);

  return [state, postSyncScores] as const;
};

const saveCsv = (data: string[][], filename: string) => {
  const csvData = data.map((row) => row.map((value) => {
    const stringValue = value.toString();
    const hasQuotes = stringValue.includes('"');
    const mustBeQuoted = hasQuotes || stringValue.includes(',');
    return mustBeQuoted ? `"${hasQuotes ? stringValue.replace(/"/g, '""') : stringValue}"` : stringValue;
  }).join(',')).join('\n');

  const filenameWithExtension = filename.endsWith('.csv') ? filename : `${filename}.csv`;

  saveAs(new Blob([ csvData ], { type: 'text/csv;charset=utf-8' }), filenameWithExtension, { autoBom: true });
};

export const useDownloadGradesCsv = (id?: string) => {
  const apiClient = useApiClient();
  const { launchToken } = useServices();
  const pushToast = usePushToast();
  const [state, setState] = React.useState<FetchState<{
    csvDownloaded: boolean; registration: string;
  }, Error>>(fetchIdle());
  const setAppError = useSetAppError();

  React.useEffect(() => {
    if (state.type === FetchStateType.ERROR) {
      setAppError(state.error);
    }
  }, [state, setAppError]);

  const downloadCsv = React.useCallback(async() => {
    if (!id) return;

    setState(previous => fetchLoading(previous));

    try {
      const {gradesAndProgress, registration} = await apiClient.apiV0GetAssignmentGradesAndProgress({
        params: { id },
        query: { t: launchToken }
      })
        .then(response => response.acceptStatus(200).load())
      ;

      // scoreMaximum is always 100 when no lineItem is given so scoreGiven is already a percentage
      const data = [['Name', 'Grade (%)', 'Completed (%)']].concat(gradesAndProgress.map(
        (gradeAndProgress) => [
          gradeAndProgress.name ?? gradeAndProgress.grade.userId ?? 'Unknown Name',
          gradeAndProgress.grade.scoreGiven.toString(),
          (gradeAndProgress.progress.scaled * 100).toString(),
        ]
      ));

      saveCsv(data, `assignment-${registration}-grades-${new Date().toISOString()}`);

      pushToast({
        title: 'Success',
        message: 'Check your computer’s Downloads folder for the spreadsheet.',
        variant: 'success',
        dismissAfterMs: 5000
      });

      setState(fetchSuccess({ csvDownloaded: true, registration }));
    } catch(error: any) {
      setState(previous => fetchError(new CsvDownloadError(error), previous));
    }
  }, [id, launchToken, pushToast, apiClient]);

  return [state, downloadCsv] as const;
};

export const useAssignmentAttempts = (id: string | undefined) => {
  const apiClient = useApiClient();
  const {launchToken} = useServices();
  const [state, setState] = React.useState<FetchState<MappedUserInfo<ActivityState>[], any>>(fetchIdle());
  const setAppError = useSetAppError();

  React.useEffect(() => {
    if (!id) return;

    setState((previous) => fetchLoading(previous));

    apiClient.apiV0GetAssignmentAttempts({ params: { id }, query: { t: launchToken } })
      .then(response => response.acceptStatus(200).load())
      .then(response => setState(fetchSuccess(response.items)))
      .catch(e => {
        setState(fetchIdle());
        setAppError(e);
      })
    ;
  }, [apiClient, id, setAppError, launchToken]);

  return state;
};

export const useAssignmentAttemptDetail = (id: string, userId: string) => {
  const apiClient = useApiClient();
  const {launchToken} = useServices();
  const [state, setState] = React.useState<FetchState<AssignmentAttemptDetail, string>>(fetchIdle());
  const setAppError = useSetAppError();

  React.useEffect(() => {
    setState((previous) => fetchLoading(previous));

    apiClient.apiV0GetAssignmentAttemptDetails({ params: { id, userId }, query: { t: launchToken } })
      .then(response => response.acceptStatus(200).load())
      .then(response => setState(fetchSuccess(response)))
      .catch(e => {
        setState(fetchIdle());
        setAppError(e);
      })
    ;
  }, [apiClient, id, setAppError, launchToken, userId]);

  return state;
};

// Note: Only call this inside a ToastProvider
export const useAssignmentManagementStates = (assignmentId: string | undefined) => {
  const launchTokenData = useLaunchTokenData();
  const [registrationMetadataState, setRegistrationMetadata] = useRegistrationMetadata(launchTokenData.registration);
  const [syncScoresState, postSyncScores] = useSyncScores(assignmentId, setRegistrationMetadata);
  const [downloadState, downloadCsv] = useDownloadGradesCsv(assignmentId);
  const assignmentAttemptsState = useAssignmentAttempts(assignmentId);

  return {
    enabled: !!assignmentId,
    registrationMetadataState,
    syncScoresState, downloadState,
    postSyncScores, downloadCsv,
    assignmentAttemptsState,
  };
};

export type AssignmentManagementStates = ReturnType<typeof useAssignmentManagementStates>;
