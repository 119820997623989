import { isActivityProgressMessage } from '@openstax/lti';
import React from 'react';

enum PostMessageTypes {
  Complete = 'complete',
  RecoveryCredentialsComplete = 'recovery-credentials-complete'
}

export const useCompleteTrigger = (onComplete: () => void) => {
  const handleCallback = React.useCallback((e: MessageEvent<any>) => {
    if (e.data.type === PostMessageTypes.Complete) {
      onComplete();
    }
  }, [onComplete]);

  React.useEffect(() => {
    window.addEventListener('message', handleCallback, false);
    return () => window.removeEventListener('message', handleCallback);
  }, [handleCallback]);
};

export const usePostComplete = () => {
  React.useEffect(() => {
    window.parent.postMessage({
      type: PostMessageTypes.Complete
    }, window.location.origin);
  }, []);
};

export const usePostRecoveryCredentialsComplete = () => {
  React.useEffect(() => {
    window.opener.postMessage({
      type: PostMessageTypes.RecoveryCredentialsComplete
    }, window.location.origin);
  }, []);
};

export const useRecoveryCredentialsCompleteTrigger = (onComplete: () => void) => {
  React.useEffect(() => {
    const handleCallback = (e: MessageEvent<any>) => {
      if (e.data.type === PostMessageTypes.RecoveryCredentialsComplete) {
        onComplete();
      }
    };

    window.addEventListener('message', handleCallback, false);
    return () => window.removeEventListener('message', handleCallback);
  }, [onComplete]);
};

export const useProgressTrigger = (onComplete: () => void) => {

  const handleCallback = React.useCallback((e: MessageEvent<any>) => {
    // Forward activity progress messages to lti-gateway
    if (isActivityProgressMessage(e.data)) {
      if (e.data.payload.isComplete) {
        onComplete();
      }
    }
  }, [onComplete]);

  React.useEffect(() => {
    window.addEventListener('message', handleCallback, false);
    return () => window.removeEventListener('message', handleCallback);
  }, [handleCallback]);
};
