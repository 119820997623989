import styled from 'styled-components';
import * as UI from '@openstax/ui-components';
import newTab from '../../assets/icons/new-tab.svg';
import { Html } from './Html';

const StyledHeading = styled.h3`
  color: ${UI.colors.palette.neutralDarker};
  font-size: 1.4rem;
  padding-left: 0;
  margin: 0;
  line-height: 2.4rem;
  a {
    margin-left: 0.6rem;
  }
  img {
    width: 1.2rem;
  }
`;

const HeadingWithLink = (props: {url?: string; title: string; ariaLabel: string}) =>
  <StyledHeading>
    <Html>{props.title}</Html>
    {props.url ? <a target="_blank" rel="noreferrer" aria-label={props.ariaLabel} href={props.url}>
      <img src={newTab} alt='new tab'/>
    </a> : null}
  </StyledHeading>;

export default HeadingWithLink;
