import React from 'react';
import { AnyOrnResource } from "@openstax/orn-locator";
import styled from 'styled-components';
import { colors } from "@openstax/ui-components";

const gridImageSize = 20;

const NoImage = styled.div`
  height: ${gridImageSize}rem;
  width: ${gridImageSize}rem;
  background-color: ${colors.palette.neutralThin};
`;

const Grid = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

  li {
    &, button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      justify-self: center;
    }

    img {
      height: ${gridImageSize}rem;
      width: ${gridImageSize}rem;
    }
    div {
      margin: 0.8rem 0;
      font-size: 1.4rem;
      width: ${gridImageSize}rem;
      text-align: left;
    }
  }

  button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`;

export const ResourceGrid = (
  props: {resources: AnyOrnResource[]; onSelect: (resource: AnyOrnResource) => void}
) => {
  return <Grid>
    {props.resources.map((resource, index) => <React.Fragment key={index}>
      {'id' in resource && 'title' in resource ?
        <li><button onClick={() => props.onSelect(resource)}>
          {'images' in resource && 'square' in resource.images
            ? <img src={resource.images.square} alt="" />
            : <NoImage />
          }
          <div>{resource.title}</div>
        </button></li>
    : null}</React.Fragment>)}
  </Grid>;
};
