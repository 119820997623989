/* spell-checker: ignore disabledwhen */
import React from 'react';
import * as UI from '@openstax/ui-components';
import styled from 'styled-components';
import { Options } from '../../hooks/options';
import { Html } from '../Html';

const inputComponents = {
  Radio: UI.Forms.Controlled.Radio,
  Checkbox: UI.Forms.Controlled.Checkbox,
  Select: UI.Forms.Controlled.Select,
} as any;

const OptionHeading = styled.h4`
  display: flex;
  align-items: center;
`;

type DynamicInputProps = {
  type: 'Radio' | 'Checkbox' | 'Select';
  props: {
    name?: string;
    tooltipText?: string;
    disabled?: boolean;
    onChange?: (e: any) => void;
    checked?: boolean;
    value?: string | boolean;
  };
};

export const DynamicInput = ({type, props}: DynamicInputProps) => {
  const {markdown, default: _, ...inputProps} = props as any;
  return React.createElement(inputComponents[type], inputProps);
};

type OptionProps = {
  option: {[key: string]: any};
  optionGroupState?: Options;
  handleChange: (e: any) => void;
  handleDisabledCheck: (disabler: {optionName: string; optionValue: string | boolean}) => void;
};

export const Option = ({option, optionGroupState, handleChange, handleDisabledCheck}: OptionProps) => <>
  <OptionHeading>{option.title} {option.description
    ? <UI.TooltipGroup placement='right'><Html>{option.description}</Html></UI.TooltipGroup>
    : null}
  </OptionHeading>
  {option.optionInputs.map(({disabledwhen, help, ...input}: {[key: string]: any}, i: number) => {
    // map each input from a given activity config to the correct input component
    const disabled = disabledwhen?.optionName && handleDisabledCheck(disabledwhen);
    const inputProps = {
      ...input,
      name: option.name,
      tooltipText: disabled ? help : '',
      disabled,
      onChange: (e: any) => { if (!disabled) handleChange(e);},
      ...(input.type === 'Radio' || input.type === 'Checkbox'
        ? {checked: input.value === optionGroupState?.[option.name]}
        : {}),
      ...(input.type === 'Select' ? {value: optionGroupState?.[option.name]} : {}),
    };

    return <DynamicInput key={i} type={input.type} props={inputProps} />;
  })}
</>;
  