import React from 'react';
import type {ActivityState} from '@openstax/ts-utils/services/lrsGateway/attempt-utils';
import styled from 'styled-components';
import * as UI from '@openstax/ui-components';

const Label = styled.span`
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
`;
const CompleteLabel = styled(Label)`
  background-color: ${UI.colors.palette.green};
  color: ${UI.colors.palette.neutralLightest};
`;
const InProgressLabel = styled(Label)`
  background-color: ${UI.colors.palette.neutral};
  color: ${UI.colors.palette.neutralLightest};
`;

export const AttemptProgressLabel = ({attempt}: {
  attempt: ActivityState;
}) => {
  return attempt.currentAttemptCompleted
    ? <CompleteLabel>Complete</CompleteLabel>
    : attempt.currentAttempt
      ? <InProgressLabel>In Progress</InProgressLabel>
      : null;
};
