import React from 'react';
import { colors, NavBar } from '@openstax/ui-components';
import styled from 'styled-components';
import { TextResizer } from '../../components/TextResizer';
import { TextResizerValue } from "../../constants";

const StyledNavBar = styled(NavBar)`
  width: 100%;

  h1 {
    max-width: 33.6rem;
    font-size: 1.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${colors.palette.neutralDarker};

    @media screen and (max-width: 41rem) {
      max-width: 21.6rem;
    }
  }
`;

export const Activity = ({ title, src, onChangeTextSize, defaultTextSize, isTextResizable }: {
  title: string;
  src: HTMLIFrameElement['src'];
  defaultTextSize: number;
  onChangeTextSize: (value: number) => void;
  isTextResizable?: boolean;
}) => {
  const [textSize, setTextSize] = React.useState<number>(defaultTextSize);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const iframeOrigin = React.useMemo(() => new URL(src).origin, [src]);

  return <>
    <StyledNavBar>
      <h1>{title}</h1>
      {isTextResizable ? <TextResizer textSize={textSize as TextResizerValue} setTextSize={(value) => {
        iframeRef.current?.contentWindow?.postMessage({type: 'TextSizeUpdate', value}, iframeOrigin);
        onChangeTextSize(value);
        setTextSize(value);
      }} /> : null}
    </StyledNavBar>
    <iframe
      ref={iframeRef}
      style={{ flex: 1 }}
      title={title}
      allowFullScreen
      allow="autoplay"
      src={src} />
  </>;
};
