import styled from 'styled-components';
import { colors } from '@openstax/ui-components';

// Note: bottomPositioning tooltips are offset on purpose
const bottomPositioning = 'top: calc(100% + 0.57rem);';
const rightPositioning = 'left: calc(100% + 0.57rem);';
const topPositioning = `left: 50%;
  transform: translate(-50%, -100%);
`;

const bottomRotateDeg = -45;
const rightRotateDeg = -135;
const topRotateDeg = 135;

const bottomAfterPositioning = `left: 2rem;
    top: -0.57rem;`;
const rightAfterPositioning = `right: 0;
    top: calc(50% - 0.7rem);`;
const topAfterPositioning = `left: calc(50% - 0.5rem);
    bottom: -0.57rem;`;

export const OldToolTip = styled.div<{ placement?: 'bottom' | 'right' | 'top' }>`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background: #fff;
  border:  1px solid #ccc;
  border-radius: 0.3rem;
  padding: 1rem;
  transition: opacity 1ms ease 1ms;
  color: ${colors.palette.neutralThin};
  white-space: nowrap;
  z-index: 10;

  ${(props) =>
    props.placement === 'right' ?
      rightPositioning :
      props.placement === 'top' ?
        topPositioning :
        bottomPositioning
  }

  :after {
    position: absolute;
    content: ' ';
    left: -0.57rem;
    clip-path: polygon(0 0, 100% 100%, 100% 0);
    display: block;
    height: 1rem;
    width: 1rem;
    background: #fff;
    border: 1px solid #ccc;
    transform: rotate(${(props) =>
      props.placement === 'right' ?
        rightRotateDeg :
        props.placement === 'top' ?
          topRotateDeg :
          bottomRotateDeg
    }deg);

    ${(props) =>
      props.placement === 'right' ?
        rightAfterPositioning :
        props.placement === 'top' ?
          topAfterPositioning :
          bottomAfterPositioning
    }
  }

  *:hover > & {
    visibility: visible;
    opacity: 1;
    transition: opacity 1ms ease 300ms;
  }
`;

export const TooltipWrapper = styled.span`
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: visible;
`;