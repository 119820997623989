import React from 'react';
import {
  fetchIdle,
  fetchLoading,
  FetchState,
  fetchSuccess,
} from "@openstax/ts-utils/fetch";
import * as UI from '@openstax/ui-components';
import { useApiClient } from '../../api';
import {
  AssignmentReadResponse,
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";

export const useGetAssignment = (id: string | undefined) => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<AssignmentReadResponse, string>>(
    id ? fetchLoading() : fetchIdle()
  );

  React.useEffect(() => {
    if (!id) {
      return;
    }
    apiClient.apiV0GetAssignment({params: {id}})
      .then(response => response.acceptStatus(200).load())
      .then(response => setState(fetchSuccess(response)))
      .catch(setAppError)
    ;
  }, [apiClient, id, setAppError]);

  return state;
};
