import { createRoute, makeScreen } from "../../core/services";
import { usePostRecoveryCredentialsComplete } from "../hooks/usePostMessage";
import { Loader } from '@openstax/ui-components';

export const RecoveryCredentialsComplete = () => {
  usePostRecoveryCredentialsComplete();
  return <Loader />;
};

export const recoveryCredentialsCompleteScreen = createRoute({
  name: 'RecoveryCredentialsCompleteScreen',
  path: '/recovery-credentials-complete',
  handler: makeScreen(RecoveryCredentialsComplete)
});
