import React from 'react';
import { useServices } from "../core/context/services";

export const useLaunchTokenFields = () => {
  const {launchToken} = useServices();

  return React.useMemo(() => {
    if (!launchToken) return {};

    // https://stackoverflow.com/a/38552302/14809536
    const base64Url = launchToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const token = JSON.parse(jsonPayload);

    return token;
  }, [launchToken]);
};

export const useLaunchTokenData = () => {
  const token = useLaunchTokenFields();

  return React.useMemo(() => {
    return 'sub' in token ? JSON.parse(token.sub) : {};
  }, [token]);
};
