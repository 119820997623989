import { editActivityScreen, selectActivityScreen } from "./ContentSelection";
import { activitySetPreviewScreen } from "./ActivitySetPreview";
import { reviewSubmissionScreen } from "./ReviewSubmissions";

export const instructorRoutes = () => ([
  activitySetPreviewScreen,
  selectActivityScreen,
  editActivityScreen,
  reviewSubmissionScreen,
]);
