import React from 'react';
import {
  SupportResourcesResponse
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/select-activity";
import {
  stateHasData,
  FetchState,
  fetchLoading,
  fetchSuccess,
} from "@openstax/ts-utils/fetch";
import styled from 'styled-components';
import { useApiClient } from "../../api";
import { selectActivity } from "../utils/select-activity";
import * as UI from "@openstax/ui-components";
import { Html } from "./Html";

const useSupportActivities = (orn: string[]) => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<SupportResourcesResponse, string>>(fetchLoading());

  React.useEffect(() => {
    apiClient.apiV0GetSupportResources({query: {orn}})
      .then(response => response.acceptStatus(200).load())
      .then(response => {
        setState(fetchSuccess(response));
      })
      .catch(setAppError)
    ;
  }, [apiClient, orn, setAppError]);

  return state;
};

const ResourceList = styled.ul`
  list-style: none;
  margin: 0 1.6rem;
  padding: 0;
  line-height: 2rem;

  h2, p {
    margin: 0 0 0.4rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      height: 4rem;
      width: 4rem;
      margin: 1rem 2rem 1rem 1rem;
    }

    > div {
      flex: 1;
      margin: 0 1.6rem;
    }

    button {
      height: 3rem;
      font-size: 1.4rem;
    }
  }
  li + li {
    margin-top: 1.6rem;
  }
`;

const Header = styled.header`
  margin: 0 1.6rem;
  line-height: 2rem;

  h1 {
    font-size: 2.1rem;
  }
`;

const SupportResources = (props: {data: SupportResourcesResponse}) => {
  return <ResourceList>
    {props.data.items.map(resource => <li key={resource.url}>
      {resource.icon ? <img src={resource.icon.url} alt="" /> : null}
      <div>
        <h2><Html>{resource.title}</Html></h2>
        <Html block>{resource.text}</Html>
      </div>
      <UI.Button onClick={() => selectActivity(resource)}>Select</UI.Button>
    </li>)}
  </ResourceList>;
};

export const SupportResourceSelect = (props: {scopes: string[]}) => {
  const state = useSupportActivities(props.scopes);

  return <>
    {stateHasData(state) ? <>
      <Header>
        <h1>Resources</h1>
        <p>
          We recommend adding these resources to your course page so that your
          students can access them outside of individual assignments.
        </p>
        <p>
          <strong>Note:</strong> Selecting any one of these resources will exit assignment
          creation and create a dedicated link on your course dashboard to the selected resource.
          You can return to this same page to create your assignment afterwards.
        </p>
      </Header>
      {stateHasData(state) ? <SupportResources data={state.data} /> : null}
    </> : <UI.Loader delay={500} />}
  </>;
};
