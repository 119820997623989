import {
  Button,
  ButtonLink,
  Modal,
  ModalBody,
  ModalCard,
  Overlay,
  OverlayBody,
  OverlayMask
} from "@openstax/ui-components";
import { UserMetadataPayload } from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/user";
import React from "react";
import styled from "styled-components";
import { renderRouteUrl } from "../../core";
import { useRecoveryCredentialsCompleteTrigger } from "../hooks/usePostMessage";
import { recoveryCredentialsCompleteScreen } from "../screens/RecoveryCredentialsComplete";

const maskMargin = '2rem';
const calloutColor = '#FDBD3E';
const mobileMediaQuery = '@media only screen and (max-width: 399px)';
const StyledOverlay = styled(Overlay)`
  padding: 4.8rem;

  ${OverlayMask} {
    top: ${maskMargin};
    bottom: ${maskMargin};
    left: ${maskMargin};
    right: ${maskMargin};
    height: calc(100% - (${maskMargin} * 2));
    width: calc(100% - (${maskMargin} * 2));
  }

  ${OverlayBody} {
    flex-direction: column;
    text-align: center;
  }

  h1 {
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 4rem;
    letter-spacing: -1.44px;

    span {
      color: ${calloutColor};
    }
  }

  p {
    font-size: 2rem;
    padding: 0 2rem;
    line-height: 3rem;

    span:after {
      content: "";
    }
  }

  ${ButtonLink} {
    text-decoration: underline;
    color: #fff;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  ${mobileMediaQuery} {
    h1 {
      font-size: 3rem;
      margin-top: 4rem;
    }

    p {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
`;

const OverlayContentWrapper = styled.div`
  max-width: 56rem;
  overflow: auto;
`;

const OverlayButtons = styled.div`
  margin: 4.8rem 0 2.4rem;

  button {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    &:not(:disabled):hover {
      background-color: #fff;
      border-color: #fff;
      color: #000;
    }
  }

  button:first-child {
    background: ${calloutColor};
    border-color: ${calloutColor};
    color: #000;
  }

  ${mobileMediaQuery} {
    button {
      width: 100%;
    }

    && button + button {
      margin: 0.8rem 0 0;
    }
  }
`;

const ConfirmModal = styled(Modal)`
  ${ModalCard} {
    max-width: 64rem;
    width: 100%;
  }

  ${ModalBody} {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
`;

const ConfirmButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  > * {
    margin-top: 0.8rem;
  }
`;

const RecoveryCredentialsOverlay = ({
  setShow,
  setShowConfirmDecline,
  accountsBase,
  authToken,
}: Pick<React.ComponentProps<typeof RecoveryCredentials>,
  'saveUserMetadata' | 'setShow' | 'accountsBase' | 'authToken'> & {
    setShowConfirmDecline: (value: boolean) => void;
}) => {
  const popupHandle = React.useRef<Window | null>();

  const externalCredentialsUrl = React.useMemo(() => {
    const returnTo = encodeURIComponent(
      window.location.origin + renderRouteUrl(recoveryCredentialsCompleteScreen, undefined)
    );
    return `${accountsBase}/external_user_credentials/new?return_to=${returnTo}&token=${authToken}`;
  }, [authToken, accountsBase]);

  const handleShowPopup = React.useCallback(() => {
    popupHandle.current = window.open(
      externalCredentialsUrl,
      'linkAccountWindow',
      'popup,width=508,height=820'
    );
  }, [externalCredentialsUrl]);

  useRecoveryCredentialsCompleteTrigger(() => {
    popupHandle.current?.close();
    setShow(false);
  });

  return <StyledOverlay onClose={() => setShowConfirmDecline(true)} show={true}>
    <OverlayContentWrapper>
      <h1>Save your <span>highlights and notes</span> with OpenStax</h1>
      <p>
        Create an OpenStax account to retain access to your highlights
        and notes even after your course ends.<br/>
        It's completely free!
      </p>
      <OverlayButtons>
        <Button onClick={() => handleShowPopup()}>Sign up</Button>
      </OverlayButtons>
      <ButtonLink onClick={() => setShowConfirmDecline(true)}>I don’t want to save my highlights and notes</ButtonLink>
    </OverlayContentWrapper>
  </StyledOverlay>;
};

const ConfirmDeclineRecoveryCredentials = ({
  saveUserMetadata,
  setShow,
  setShowConfirm
}: Pick<React.ComponentProps<typeof RecoveryCredentials>, 'saveUserMetadata' | 'setShow'> & {
  setShowConfirm: (value: boolean) => void;
}) => {
  const storeDecline = React.useCallback(() => {
    saveUserMetadata({ recoveryCredentialsDeclined: true });
    setShow(false);
  }, [saveUserMetadata, setShow]);

  return <ConfirmModal
    heading='Lose access to your notes and highlights?'
    onModalClose={() => setShow(false)} show={true}
  >
    <ModalBody>
      <p>Are you sure you want to lose access to your highlights and notes after this course ends?</p>
      <p>
        If you do not opt to create recovery credentials now, you will not have access to notes and
        highlights you created in this course.
      </p>
      <ConfirmButtons>
        <Button variant='light' onClick={storeDecline}>Yes, I don't need access to my notes and highlights</Button>
        <Button onClick={() => setShowConfirm(false)}>Cancel</Button>
      </ConfirmButtons>
    </ModalBody>
  </ConfirmModal>;
};

export const RecoveryCredentials = ({ setShow, accountsBase, authToken, saveUserMetadata }: {
  setShow: (value: boolean) => void;
  accountsBase: string;
  authToken: string;
  saveUserMetadata: (payload: UserMetadataPayload) => void;
}) => {
  const [showConfirmDecline, setShowConfirmDecline] = React.useState(false);

  if (showConfirmDecline) {
    return <ConfirmDeclineRecoveryCredentials
      setShowConfirm={setShowConfirmDecline}
      saveUserMetadata={saveUserMetadata}
      setShow={setShow}
    />;
  }

  return <RecoveryCredentialsOverlay
    setShow={setShow}
    accountsBase={accountsBase}
    authToken={authToken}
    saveUserMetadata={saveUserMetadata}
    setShowConfirmDecline={setShowConfirmDecline}
  />;
};
