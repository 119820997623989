import React from 'react';

const useMatchMobileQuery = () => {
  const matchMedia = window.matchMedia('(max-width: 620px)');
  const [matches, setMatches] = React.useState(matchMedia.matches);

  const listener = React.useCallback((e: MediaQueryListEvent) => {
    if (e.matches) {
      setMatches(true);
    } else {
      setMatches(false);
    }
  }, []);

  React.useEffect(() => {
    if (typeof matchMedia.addEventListener === 'function') {
      matchMedia.addEventListener('change', listener);
    } else {
      matchMedia.addListener(listener);
    }
    return () => {
      if (typeof matchMedia.removeEventListener === 'function') {
        matchMedia.removeEventListener('change', listener);
      } else {
        matchMedia.removeListener(listener);
      }
    };
  }, [listener, matchMedia]);

  return matches;
};

export default useMatchMobileQuery; 