import styled from 'styled-components';
import { Html } from '../instructor/components/Html';

const StyledWarning = styled.div`
  background: #fff5e0;
  color: #976502;
  border: 1px solid #fdbd3e;
  padding: .6rem 1.6rem;
  margin-bottom: 1.6rem;
  line-height: 2rem;

  span:first-child {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const Warning = (props: {messages: string[]}) => {
  const numWarnings = props.messages.length;

  return <StyledWarning>
    <span>Warning: </span>
    {props.messages.map((message, i) => 
      <Html block={numWarnings > 1} key={i}>
        {numWarnings > 1 ? `[${i + 1} of ${numWarnings}]: ${message}`: message}
      </Html>
    )}
  </StyledWarning>;
};

export default Warning;
