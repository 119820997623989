import { LtiResourceLink } from '@openstax/lti/lti-structures/LtiResourceLink';
import { makeDeepLinkContentSelectedMessage } from '@openstax/lti/lti-gateway/post-messages';

export const selectActivity = (activity: LtiResourceLink | {item: LtiResourceLink; editUrl?: string}) => {
  window.parent.postMessage(
    makeDeepLinkContentSelectedMessage([
      activity
    ]),
    '*'
  );
};

export const shouldHideLicense = (licenseName: string | undefined) => {
  const hiddenLicenseNames = [
    'Creative Commons Attribution License'
  ];
  return !licenseName || hiddenLicenseNames.includes(licenseName);
};
