import {
  UserMetadataPayload,
} from '@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/user';
import {
  FetchState,
  fetchIdle,
  fetchLoading,
  fetchSuccess,
} from '@openstax/ts-utils/fetch';
import React from 'react';
import { useApiClient } from '../../api';

export const useUserMetadata = () => {
  const apiClient = useApiClient();

  type ResponsePromise = ReturnType<
    typeof apiClient.apiV0GetUserMetadata | typeof apiClient.apiV0WriteUserMetadata
  >;

  const [state, setState] = React.useState<FetchState<UserMetadataPayload, string>>(fetchIdle());

  const setStateFromResponse = React.useCallback((responsePromise: ResponsePromise) => {
    responsePromise.then((response) => response.acceptStatus(200).load())
                   .then((response) => setState(fetchSuccess(response)));
  }, []);

  React.useEffect(() => {
    setStateFromResponse(apiClient.apiV0GetUserMetadata({}));
  }, [apiClient, setStateFromResponse]);

  const save = React.useCallback((payload: UserMetadataPayload) => {
    setState((previous) => fetchLoading(previous));
    setStateFromResponse(apiClient.apiV0WriteUserMetadata({ payload }));
  }, [apiClient, setStateFromResponse]);

  return [state, save] as const;
};
