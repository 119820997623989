import styled, { css } from 'styled-components';
import { colors } from '@openstax/ui-components';
import { narrowMediaQuery, visuallyHidden } from '../../../utils/styleUtils';

export const DeleteButton = styled.button`
  align-self: flex-start;
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
  border: none;
  margin: 0;
  background: none;
  cursor: pointer;

  &:hover {
    background: ${colors.palette.pale};
  }
`;

export const GrabHandle = styled.button`
  align-self: center;
  position: absolute;
  height: 1.6rem;
  width: 2rem;
  left: 1rem;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
  }
`;

export const StepTitleContainer = styled.div`
  font-weight: 700;
  margin-bottom: 0.8rem;
`;

export const StepInfo = styled.div`
  margin-right: 1.6rem;
  flex: 1;
  overflow: auto;

  & > *:last-child {
    line-height: 2rem;
  }
`;

export const StepIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.6rem;
  height: 1.6rem;
  width: 1.6rem;
  background: ${colors.palette.neutralThin};
  color: ${colors.palette.white};
  flex-shrink: 0;

  img {
    height: 0.8rem;
  }
`;

export const StyledStep = styled.li<{isDragging?: boolean; showHandle?: boolean; canEdit?: boolean}>`
  padding: 1.6rem 3.6rem;
  position: relative;
  display: flex;

  &:not(:first-child) {
    border-top: solid 1px ${colors.palette.pale};
  }

  &:not(:hover, :focus, :focus-visible, :focus-within) {
    ${DeleteButton} {
      ${visuallyHidden}
    }
  }

  ${DeleteButton} {
    ${(props) => props.isDragging ? visuallyHidden : ''};
  }

  input {
    height: 2rem;
    padding-left: 1.2rem;
    margin-right: 0.8rem;
    max-width: 4rem;
  }

  button {
    border: none;
    padding: 0;
    background: none;
  }
`;

export const StepBody = styled.div`
  display: flex;
  flex: 1;

  ${narrowMediaQuery(css`
    flex-direction: column;
  `)}
`;

export const Description = styled.div<{isReadingMore?: boolean}>`
  position: relative;

  button {
    margin: 0;
    font-weight: 700;
    font-size: 1.2rem;
    color: ${colors.palette.mediumBlue};
    cursor: pointer;
  }

  p {
    margin: 0;
    ${(props) => props.isReadingMore
      ? css``
      : css`
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
      `};
  }

`;

export const Points = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;

  ${narrowMediaQuery`
    margin-top: 0.8rem;
  `}
`;

export const NumberInput = styled.input`
  min-width: 5.8rem;
  min-height: 2.6rem;
`;

export const StyledSortableItem = styled.li`
  list-style-type: none;

  &:active {
    position: relative;
    z-index: 10;
  }

  &:not(:hover, :focus, :active, :focus-visible, :focus-within) {
    ${GrabHandle} {
      ${visuallyHidden}
    }
  }
`;

export const StepGroup = styled.ul<{isDragging?: boolean; showDelete?: boolean; showDeleteActive?: boolean}>`
  border: solid 1px #d5d5d5;
  margin: 1.6rem 0;
  padding: 0;
  background: ${colors.palette.white};
  box-shadow: ${(props) => props.isDragging ? '0px 2px 4px rgba(0, 0, 0, 0.2)' : 'none'};

  ${DeleteButton} {
    ${(props) => props.showDelete && !props.isDragging ? css`display: flex;` : ''}
    ${(props) => props.showDeleteActive ? css`background: ${colors.palette.pale};` : ''}
  }
`;
