"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hashSearchResult = exports.applyTopicSort = exports.applyTopicSortSync = exports.isIntegrationActivity = exports.topicRelations = void 0;
const orn_locator_1 = require("@openstax/orn-locator");
const ts_utils_1 = require("@openstax/ts-utils");
exports.topicRelations = [
    'primer',
    'preparedness',
    'learning',
    'practice',
    'assessment',
    'reflection',
    'review', // assessment or activity to refresh knowledge for longer term recollection
];
const isIntegrationActivity = (thing) => 'id' in thing;
exports.isIntegrationActivity = isIntegrationActivity;
const makeTopicRelationList = (scope) => {
    let topicRelationsList = [];
    for (const relation of exports.topicRelations.slice(0, exports.topicRelations.indexOf('learning'))) {
        topicRelationsList.push(`${relation}:${scope.orn}`);
    }
    // Recursively add child topics after primer and preparedness
    if ('contents' in scope) {
        for (const child of scope.contents) {
            topicRelationsList = [...topicRelationsList, ...makeTopicRelationList(child)];
        }
    }
    for (const relation of exports.topicRelations.slice(exports.topicRelations.indexOf('learning'))) {
        topicRelationsList.push(`${relation}:${scope.orn}`);
    }
    return topicRelationsList;
};
const applyTopicSortSync = (scopeResource, activities) => {
    const scopeChildResourceList = makeTopicRelationList(scopeResource);
    const mostAdvancedTopic = (activity) => {
        const topics = Object.entries(activity.topics);
        topics.sort((a, b) => scopeChildResourceList.indexOf(`${b[1]}:${b[0]}`) - scopeChildResourceList.indexOf(`${a[1]}:${a[0]}`));
        return topics[0];
    };
    const sorted = [...activities];
    sorted.sort((a, b) => {
        const tA = mostAdvancedTopic(a);
        const tB = mostAdvancedTopic(b);
        return scopeChildResourceList.indexOf(`${tA[1]}:${tA[0]}`) - scopeChildResourceList.indexOf(`${tB[1]}:${tB[0]}`);
    });
    return sorted;
};
exports.applyTopicSortSync = applyTopicSortSync;
const applyTopicSort = async (scope, activities) => {
    const scopeResource = typeof scope === 'string' ? await (0, orn_locator_1.locate)(scope) : scope;
    return (0, exports.applyTopicSortSync)(scopeResource, activities);
};
exports.applyTopicSort = applyTopicSort;
const hashSearchResult = (result) => {
    const { id, launch, launchParams, topics, integration, type, payload, resources } = result;
    const hashable = { id, launch, launchParams, topics, integration, type, payload, resources };
    // hashValue should accept optional or undefined hash values but
    // it doesn't
    return (0, ts_utils_1.hashValue)(hashable);
};
exports.hashSearchResult = hashSearchResult;
