import styled from 'styled-components';

export const LinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  color: #0000EE;
  cursor: pointer;
`;

