import React from 'react';
import styled, { css } from 'styled-components';
import * as UI from '@openstax/ui-components';
import pencilDark from '../../assets/icons/pencil-dark.svg';

const HeadingWrapper = styled.div`
  line-height: 1.6rem;
  margin-bottom: 0.8rem;

  h2 {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    color: ${UI.colors.palette.neutralDarker};
  }

  img {
    width: 1.2rem;
  }
`;

const EditButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0 0 0 0.5rem;
  cursor: pointer;
`;

const EditInput = styled.input<{focused: boolean}>`
  width: 45%;
  height: 4rem;
  margin: 0 0.8rem 0 0;
  padding: 0 1.6rem;
  font-size: 1.6rem;
  border: solid 0.1rem;
  border-radius: 0.2rem;

  ${(props) => props.focused
    ? css`
      border: solid 1px ${UI.colors.palette.neutralLightBlue};
      box-shadow: 0 0 4px 0 rgba(13, 192, 220, 0.5);
      outline: none;
    ` : css``}
  
`;

const EditableHeading = (props: {title: string; handleEdit?: (title: string) => void; loading?: boolean}) => {
  const [title, setTitle] = React.useState(props.title);
  const [editable, setEditable] = React.useState(false);
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    };
  }, [editable]);

  const openEdit = () => {
    setEditable(true);
  };

  const closeEdit = () => {
    if (props.handleEdit) {
      props.handleEdit(title);
    }
    setEditable(false);
  };

  return <HeadingWrapper>
      <h2>
        {editable
          ? <EditInput ref={ref} focused={editable} onChange={(e) => setTitle(e.target.value)} defaultValue={title} />
          : title}
        {!props.handleEdit
          ? null
          : editable
            ? <UI.Button
                onClick={() => closeEdit()}
                disabled={props.loading || title.length === 0}>Finish editing</UI.Button>
            : <EditButton onClick={() => openEdit()}>
              <img src={pencilDark} alt='edit' />
            </EditButton>
        }
      </h2>
    </HeadingWrapper>;
};

export default EditableHeading;