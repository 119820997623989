import React from 'react';
import { stateHasData } from '@openstax/ts-utils/fetch';
import pencil from '../../assets/icons/pencil.svg';
import type {ActivityState} from '@openstax/ts-utils/services/lrsGateway/attempt-utils';
import type {MappedUserInfo} from '@openstax/ts-utils/services/accountsGateway';
import styled from 'styled-components';
import * as UI from '@openstax/ui-components';
import leftArrow from '../../assets/icons/left.svg';
import type {
  AssignmentReadResponse
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";
import type {
  Score
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/utils/xapiUtils";
import { useAssignmentAttemptDetail } from "../hooks/grades";
import { AttemptProgressLabel } from "./AttemptProgressLabel";
import { Toasts } from "../../toasts/ToastContext";

const dateFormat = (timestamp: string | undefined) => timestamp
  ? new Date(timestamp).toLocaleString(undefined, {dateStyle: 'short', timeStyle: 'short'})
  : '';


const scoreFormat = (score: Score) => [
  score.raw
    ? `${score.raw}/${score.max}`
    : '',
  score.scaled
    ? `${(score.scaled * 100).toFixed(2)}%`
    : ''
].filter(s => !!s).join(' ');

const BackButton = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0;

  background-color: ${UI.colors.palette.neutralDarker};
  mask: url(${leftArrow}) no-repeat center;
  mask-size: contain;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
`;

const HeaderRow = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Heading = styled.h2`
  margin: 1rem;
  color: ${UI.colors.palette.neutralDarker};
`;

const AssignmentDetail = styled.dl`
  font-size: 2rem;
  margin: 1rem;

  dt {
    margin-top: 1rem;
    font-weight: bold;
  }
  dd {
    margin: 0;
  }
`;

const ActivitiesTable = styled.table`
  font-size: 2rem;
  color: ${UI.colors.palette.neutralDarker};
  width: 100%;
  padding: 1rem;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid ${UI.colors.palette.neutralThin};
  }
  tr:last-child {
    border-bottom: none;
  }

  td, th {
    padding: 1rem;
  }
  tr > .title {
    display: flex;
    flex-direction: row;
    text-align: left;

    .title-text {
      flex: 1;
    }
  }
  tr > .status {
    text-align: center;
  }
  tr > .date, tr > .actions, tr > .score {
    text-align: right;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    margin-right: 0.8rem;
    background: ${UI.colors.palette.neutralThin};
    color: ${UI.colors.palette.white};
    flex-shrink: 0;

    img {
      height: 1.1rem;
    }
  }
`;

export const AssignmentSubmissionDetails = ({assignment, attempt, onBack}: {
  assignment: AssignmentReadResponse;
  attempt: MappedUserInfo<ActivityState>;
  onBack: () => void;
}) => {
  const detail = useAssignmentAttemptDetail(assignment.id, attempt.uuid);

  return <>
    <Toasts />
    <HeaderRow>
      <BackButton onClick={onBack} />
      <Heading>{attempt.fullName} <AttemptProgressLabel attempt={attempt.data} /></Heading>
    </HeaderRow>
    {stateHasData(detail)
      ? <>
        <AssignmentDetail>
          <dt>Score</dt>
          <dd>{scoreFormat(detail.data.score)}</dd>
          <dt>Progress</dt>
          <dd>{(detail.data.progress.scaled * 100).toFixed(2)}%</dd>
        </AssignmentDetail>
        <ActivitiesTable>
          <thead>
            <tr>
              <th className="title">Name</th>
              <th className="status">Status</th>
              <th className="score">Score</th>
              <th className="date">Started</th>
              <th className="date">Completed</th>
              <th className="actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            {detail.data.activities.map(({activity, state, score}) => <tr key={activity.stepId}>
              <td className="title">
                <span className="icon">
                  {(<img src={activity.icon?.url || pencil} aria-hidden={true} alt='' />)}
                </span>
                <span className="title-text">{activity.title}</span>
              </td>
              <td className="status"><AttemptProgressLabel attempt={state} /></td>
              <td className="score">{scoreFormat(score)}</td>
              <td className="date">{dateFormat(state.currentAttempt?.timestamp)}</td>
              <td className="date">{dateFormat(state.currentAttemptCompleted?.timestamp)}</td>
              <td className="actions"></td>
            </tr>)}
          </tbody>
        </ActivitiesTable>
      </>
      : <UI.Loader />
    }
  </>;
};
